<template>
  <div class="menu">
    <basic-switch-button
      v-model="selectedTab"
      :items="tabs"
      class="menu__switch"
      name="menu-switch"
    />
    <keep-alive>
      <component
        :is="currentTab.component"
        v-if="selectedTab"
        :key="currentTab.value"
        @close="$emit('close')"
      />
    </keep-alive>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, onMounted, reactive, ref } from 'vue'

import BasicSwitchButton from '@/components/Basic/SwitchButton'

// INIT
defineEmits(['close'])
const DEBUG_OPTION_KEY = 'sanitas-dvp-debug'

// DATA
const debugData = reactive({
  mobile: null,
  email: null,
})

const selectedTab = ref('basket')

// COMPUTED
const tabs = computed(() => {
  return [
    {
      component: defineAsyncComponent(() => import('./Tabs/Basket')),
      text: 'Basket',
      value: 'basket',
    },
    {
      component: defineAsyncComponent(() => import('./Tabs/Partner')),
      text: 'Partner',
      value: 'partner',
    },
  ]
})

const currentTab = computed(() => {
  return tabs.value.find(tab => tab.value === selectedTab.value)
})

// METHODS

// LIFECYCLE HOOKS
onMounted(() => {
  try {
    const data = JSON.parse(localStorage.getItem(DEBUG_OPTION_KEY))

    debugData.mobile = data.mobile
    debugData.email = data.email
  } catch {
    /*noop*/
  }
})
</script>

<style name="mobile" scoped>
.menu__switch {
  margin: var(--fixed-spacing-fix-08) 0 var(--fixed-spacing-fix-04);
  justify-content: center;
}

.debug {
  &__btn {
    position: relative;
    font-size: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:after {
      position: absolute;
      content: ' ';
      height: 5px;
      width: 8px;
      top: 5px;
      left: calc(50% - 4px);
      background-color: transparent;
      z-index: -1;

      transition: background-color 0.2s ease-in-out;
    }

    &.is-loading:after {
      background-color: red;
    }
  }

  &__sections {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  &__section {
    form {
      display: flex;
      flex-direction: column;
    }
  }
}
.debug__session {
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 100%;
}
</style>
