<template>
  <!-- prettier-ignore !-->
  <footer
    v-show="contactInfo"
    class="contact-block appearance-extended the-grid"
  >
    <div>
      <template v-if="contactInfo?.phone?.enabled">
        <div class="center__data">
          <span
            class="title-2"
            v-text="contactInfo.phone.name"
          />
          <span
            v-for="(time, index) in contactInfo.phone.times"
            :key="index"
            class="body-2-expanded"
            v-text="`${time.days} ${time.hours}`"
          />
        </div>

        <div class="center__btns">
          <basic-button
            v-for="contactButton in contactButtons"
            :key="contactButton.label"
            hierarchy="secondary"
            :label="contactButton.label"
            @click="contactButton.action"
          >
            <template #leading>
              <the-icon
                :art="contactButton.icon.art"
                :name="contactButton.icon.name"
              />
            </template>
          </basic-button>
        </div>
      </template>
    </div>
    <div>
      <div
        class="title-2"
        v-text="$t('application.footer.help')"
      />

      <div
        v-for="link in help_links"
        :key="link.label"
      >
        <basic-link
          :v-unblu-hidden="link.unbluHidden"
          target="_blank"
          :href="link.url"
          :label="link.label"
          @click="link.action"
        >
          <template #trailing>
            <the-icon
              art="solid"
              name="arrow-right-long"
            />
          </template>
        </basic-link>
      </div>
    </div>
    <div class="contact-block__links">
      <div class="links__general">
        <basic-link
          v-for="link in general_links"
          :key="link.label"
          target="_blank"
          size="body-3"
          :href="link.url"
          :label="link.label"
        />
      </div>
      <div>
        <span
          class="caption-regular"
          style="margin-right: 8px"
          v-text="`vp-${currentViewport}`"
        />
        <span
          class="caption-regular"
          v-text="`v${version$}`"
        />
      </div>
    </div>
  </footer>
</template>

<script setup>
import { computed, inject } from 'vue'
import { useRoute } from 'vue-router'

import { loading$ } from '@/services'

import basketAPI from '@/api/basket'
import basketStore from '@/store/basket'
import useBrowser from '@/hooks/useBrowser'
import useContact from '@/hooks/useContact'
import useI18n from '@/hooks/useI18n'
import useStatus from '@/hooks/useStatus'

import BasicButton from '@/components/Basic/Button'
import BasicLink from '@/components/Basic/Link'

import { CHANNEL, ROUTE_NAME } from '@/config/constants'

// HOOKS
const { contactInfo } = useContact()
const { t } = useI18n()
const route = useRoute()
const { isOnboardingSecured } = useStatus()
const { currentViewport } = useBrowser()

// INIT
const version$ = inject('version$')

// DATA
const general_links = [
  {
    url: t('application.footer.imprint.link'),
    label: t('application.footer.imprint.title'),
  },
  {
    url: t('application.footer.legalNotice.link'),
    label: t('application.footer.legalNotice.title'),
  },
]

// COMPUTED
const contactButtons = computed(() => {
  return [
    {
      label: contactInfo.value?.phone?.nr,
      icon: { art: 'solid', name: 'phone' },
      action: () => {
        window.location = `tel:${contactInfo.value?.phone?.nr}`
      },
    },
    ...(basketStore.basket.channel === CHANNEL.PARTNERDVP
      ? [
          {
            label: contactInfo.value?.email[basketStore.basket.language.toLowerCase()],
            icon: { art: 'regular', name: 'envelope' },
            action: () =>
              (window.location = `mailto:${contactInfo.value?.email[basketStore.basket.language.toLowerCase()]}`),
          },
        ]
      : []),
  ]
})

const help_links = computed(() => {
  return [
    {
      url: t('screen.submit.furtherOptions.cancellation.href'),
      label: t('screen.submit.furtherOptions.cancellation.link'),
      action: undefined,
    },
    ...(showOfferState.value
      ? [
          {
            url: undefined,
            label: t('application.footer.showOverviewPdf'),
            action: () => openDocument(),
            unbluHidden: true,
          },
        ]
      : []),
  ]
})

const showOfferState = computed(() => {
  return isOnboardingSecured.value && route.name !== ROUTE_NAME.REENTRY && !route.meta.hideDefaultOverviewLink
})

// METHODS
async function openDocument() {
  loading$.start()

  await basketAPI.getDocument({
    basketId: basketStore.basket.basketId,
    fileName: 'overview',
  })

  loading$.end()
}
</script>

<style scoped>
.contact-block {
  color: var(--on-surface);
  padding: var(--dotcom-responsive-spacing-res-xxl) 0 var(--fixed-spacing-fix-10);
  display: grid;
  gap: var(--dotcom-responsive-spacing-res-m) var(--dotcom-responsive-spacing-res-xxl);
  grid-template-columns: 1fr;
}

.center__data {
  display: flex;
  flex-direction: column;
  margin: 0 0 var(--dotcom-responsive-spacing-res-xxxs);
}

.center__btns {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contact-block__links {
  display: flex;
  flex-direction: column;
  gap: var(--fixed-spacing-fix-04);
  color: var(--Interaction-States-enabled-extended);
  border-top: 1px solid var(--Interaction-States-enabled-extended);
  padding: var(--fixed-spacing-fix-06) 0 0;
}

.links__general {
  display: flex;
  flex-direction: column;
  gap: var(--fixed-spacing-fix-04);
}

@media (--v-medium) {
  .contact-block {
    grid-template-columns: auto 1fr;
  }

  .contact-block__links {
    flex-direction: row;
    justify-content: space-between;
    grid-column: 1 / -1;
  }

  .links__general {
    flex-direction: row;
    gap: var(--dotcom-responsive-spacing-res-s);
  }
}
</style>
