<template>
  <!-- prettier-ignore -->
  <basic-modal
    id="onboardingModal"
    ref="modalRef"
    type="raw"
    :modal-title="$t('screen.onboarding.title')"
  >
    <div class="screen">
      <basic-form
        ref="contactDataFormRef"
        class="form"
        name="changeAuthInfo"
        tracking-disabled
        :validator="v"
        @cancel="cancel"
        @submit="submit"
      >
        <template v-if="!isVerificationNeeded">
          <div class="frames__frame">
            <div
              v-text="$t('screen.onboarding.existingCustomerText')"
            />
            <basic-phone
              v-model="formData.mobile"
              class="form__input"
              autofocus
              name="mobile"
              required
              :delay="10000"
              :placeholder="$t('person.mobile')"
              :label="$t('person.mobile')"
              :v="v.mobile"
            />
            <basic-text-input
              v-model="formData.email"
              class="form__input"
              name="email"
              required
              type="email"
              :placeholder="$t('person.email')"
              :label="$t('person.email')"
              :v="v.email"
            />
          </div>
        </template>
        <template v-else>
          <div class="frames__frame">
            <div
              type="bodyMediumLong"
              v-text="$t('screen.reentry.tanHelptext', {
                mobile: formData.mobile
              })"
            />
            <basic-code-input
              v-model="formData.code"
              name="code"
              :label="$t('screen.reentry.insertTan')"
              :v="v.code"
            />
          </div>
        </template>

        <template #progress>
          <basic-button
            hierarchy="primary"
            type="submit"
            :label="$t('form.saveAndContinue')"
          />
        </template>

        <basic-modal-footer :target="modalRef">
          <basic-button
            hierarchy="primary"
            type="submit"
            :label="$t('form.saveAndContinue')"
            @click="contactDataFormRef.submit()"
          />

          <basic-button
            hierarchy="secondary"
            :label="$t('form.cancel')"
            @click="cancel"
          />

          <basic-text-button
            v-if="isVerificationNeeded"
            :disabled="isResendCodeDisabled"
            :label="$t('screen.reentry.resend')"
            @click="submit(false)"
          />
        </basic-modal-footer>
      </basic-form>
    </div>
  </basic-modal>
</template>

<script setup>
import { reactive, ref, watch } from 'vue'

import useVuelidate from '@vuelidate/core'

import { events$, loading$ } from '@/services'
import { validations } from '@/validations/onboarding'

import basketStore from '@/store/basket'
// import sessionStore from '@/store/session'

import {
  BasicButton,
  BasicCodeInput,
  BasicForm,
  BasicModal,
  BasicModalFooter,
  BasicTextButton,
  BasicTextInput,
  BasicPhone,
} from '@/components/Basic'

import { EVENT_BASKET, SOURCE } from '@/config/events'

// INIT
const emit = defineEmits(['close'])

// DATA
const formData = reactive({
  code: undefined,
  email: basketStore.basket.email,
  mobile: basketStore.basket.mobile,
})
const isResendCodeDisabled = ref(false)
const isVerificationNeeded = ref(false)
const modalRef = ref()
const contactDataFormRef = ref()

// METHODS
function cancel() {
  emit('close')
}

async function submit() {
  loading$.start({ blocking: false })

  isResendCodeDisabled.value = true
  window.setTimeout(() => (isResendCodeDisabled.value = false), 60 * 2 * 1000)

  const params = {
    emailType: 'OFFER_REENTRY',
  }

  if (isVerificationNeeded.value) {
    params.mobileNumberVerificationSmsTan = formData.code
    delete formData.code
  }
  let __basket = formData

  try {
    __basket = await basketStore.updateOvpBasket(__basket, {}, params)
    events$.emit(EVENT_BASKET.SAVED, {
      basket: __basket,
      source: SOURCE.ONBOARDING,
      update: true,
    })

    cancel()
  } catch (error) {
    if (error?.messages?.find(m => m.errorCode === 'MOBILE_NUMBER_VERIFICATION_REQUIRED')) {
      // FLEX-3585 X-Softlogin-Token not present in headers
      // sessionStore.setSession(__basket)
      basketStore.persistBasket(__basket)
      isVerificationNeeded.value = true
    } else {
      isResendCodeDisabled.value = false
    }
  }

  loading$.end()
}

// VALIDATION
const v = useVuelidate(validations, formData, { $lazy: true })

// WATCHER
watch(
  () => modalRef.value?.isOpen,
  isOpen => {
    if (isOpen) {
      formData.email = basketStore.basket.email
      formData.mobile = basketStore.basket.mobile
      isVerificationNeeded.value = false
    }
  }
)

// EPILOGUE
defineExpose({ modalRef })
</script>

<style scoped></style>
