<template>
  <!-- prettier-ignore -->
  <div class="session">
    <div class="group">
      <button
        v-for="language in SUPPORTED_LANGUAGES"
        :key="language.value"
        class="link link--grouped"
        :class="{'link--selected': selectedLanguage === language.value}"
        @click="changeLanguage(language.value)"
      >
        <span
          class="body-2"
          v-text="language.text"
        />
        <the-icon
          v-if="selectedLanguage === language.value"
          art="solid"
          name="check"
        />
      </button>
    </div>

    <div class="group">
      <button
        v-if="isContactDataEditable"
        class="link body-2"
        @click="events$.emit(EVENT_ONBOARDING.OPEN)"
        v-text="$t('screen.sessionmenu.profile')"
      />
    </div>

    <div class="group">
      <button
        v-if="showRestart"
        class="link body-2"
        data-track-as="restart"
        :data-track-text="CHANNEL.OVP"
        @click="restartApplication()"
        v-text="$t('screen.sessionmenu.restart')"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import { events$, loading$ } from '@/services'

import basketStore from '@/store/basket'
import useAuth from '@/hooks/useAuth'
import useBasket from '@/hooks/useBasket'
import useI18n from '@/hooks/useI18n'
import useStatus from '@/hooks/useStatus'

import { CHANNEL, ROUTE_NAME, SUPPORTED_LANGUAGES } from '@/config/constants'
import { EVENT_ONBOARDING } from '@/config/events'

// HOOKS
const { restartApplication } = useAuth()
const { updateLanguage } = useBasket()
const { isOnboardingSecured } = useStatus()
const { selectedLanguage } = useI18n()
const route = useRoute()

// DATA

// COMPUTED
const isContactDataEditable = computed(() => {
  return isOnboardingSecured.value && !basketStore.basket.existingCustomer && route.name !== ROUTE_NAME.ONBOARDING
})

const showRestart = computed(() => {
  return !basketStore.isTemporaryBasket()
})

// METHODS
async function changeLanguage(language) {
  loading$.start()
  await updateLanguage({ language, isUserTriggered: true })
  loading$.end()
}
</script>

<style scoped>
.session {
  display: flex;
  flex-direction: column;

  .group {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      border-top: 1px solid var(--outline-variant);
    }

    .link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: var(--fixed-spacing-fix-02) var(--fixed-spacing-fix-04);
      text-align: left;

      text-decoration: underline;
      text-decoration-color: transparent;

      flex: 1 1 auto;
      border: none;
      background: none;
      cursor: pointer;
      height: initial;
      min-height: 48px;
      color: var(--on-surface);
      transition-property: background-color, text-decoration-color;
      transition-duration: 0.5s;
      transition-timing-function: linear;

      &--selected {
        background-color: var(--Interaction-States-Dropdowns-Selected);
      }

      &:hover,
      &.link--selected:hover {
        background-color: var(--Interaction-States-Dropdowns-hovered);
        text-decoration-color: var(--on-surface);
      }

      &:focus,
      &.link--selected:focus {
        background-color: var(--Interaction-States-Dropdowns-focused);
        text-decoration-color: var(--on-surface);
      }

      &:active,
      &.link--selected:active {
        background-color: var(--Interaction-States-Dropdowns-pressed);
        text-decoration-color: var(--on-surface);
      }

      &--grouped {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .icon {
          color: var(--on-surface-medium);
          font-size: 18px;
        }
      }
    }
  }
}
</style>
